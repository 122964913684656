<template>
  <div class="login_form_wrapper">
    <p class="title">{{ $t('loginClient.header') }}</p>
    <div class="form-box">
      <el-form :model="loginForm" ref="loginForm" :rules="loginFormRules" auto-complete="off">
        <div v-if="!needVerification" class="input_wrapper">
          <el-form-item prop="userName_login">
            <div class="self_label">{{ $t('common.field.email') }}</div>
            <el-input v-model.trim="loginForm.userName_login" :placeholder="$t('common.field.email')" autocomplete="off"
              tabindex="1" size="default" :maxlength="64"></el-input>
          </el-form-item>
          <el-form-item class="pass" prop="password_login">
            <div class="self_label">{{ $t('common.field.pw') }}</div>
            <el-input type="password" v-model="loginForm.password_login" :placeholder="$t('common.field.pw')"
              autocomplete="off" tabindex="2" show-password size="default" :maxlength="20"
              @keyup.enter.native="loginSubmit">
            </el-input>
          </el-form-item>
          <el-button class="login_btn" tabindex="3" @click.native="loginSubmit" data-testid="login">
            {{ $t('common.button.login') }}
          </el-button>
          <p class="forgot_pass" @click="onForgotPass">
            {{ $t('common.button.forgotPw') }}
          </p>
        </div>
        <div class="verification-wrapper" v-else>
          <slide-verify :l="45" :r="10" :w="300" :h="200" :slider-text="$t('common.verification.toSlide')" :imgs="imgs"
            ref="slideblock" @again="onAgain" @success="onSuccess" @fail="onFail" @refresh="onRefresh">
          </slide-verify>
        </div>
        <div v-show="msg" class="verify-message">{{ msg }}</div>
      </el-form>
    </div>
  </div>
</template>

<script>
import loginClientMixin from '@/mixins/page/loginClient';

export default {
  name: 'loginClient',
  mixins: [loginClientMixin],
};
</script>

<style lang="scss">
.login_form_wrapper {
  .title {
    font-size: 24px;
    color: #09090D;
    line-height: 36px;
    font-weight: 700;
    text-align: center;
  }

  .self_label {
    font-size: 16px;
    color: $text-primary;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 8px;
    @include rtl-sass-prop-dual(text-align, left, text-align, right);
  }

  .login_btn {
    width: 100%;
    height: 40px;
    font-size: 14px;
    font-weight: 700;
    color: $white;
    border-radius: 8px;
    background: $secondary;
    margin-top: 16px;
  }

  .forgot_pass {
    font-size: 14px;
    line-height: 22px;
    color: $primary;
    cursor: pointer;
    text-align: center;
    margin-top: 24px;
  }

  .verification-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  .verify-message {
    color: $primary;
    margin-top: 24px;
    text-align: center;
  }

  .el-button:active {
    border: none;
  }

  .el-input__inner {
    border-radius: 8px;
    padding: 8px 16px;
  }

  .el-input__suffix {
    @include rtl-sass-prop(right, left, 10px);
  }

  .el-form-item:nth-child(1) {
    margin-top: 24px;
  }

  .el-input__inner:-webkit-autofill {
    box-shadow: 0 0 0px 1000px white inset !important;
  }
}
</style>
