import { md5, rsa } from '@/util/encrypt.js'
import { apiSecurityPassed } from '@/resource'
import helper from '@/util/signinHelper'
import v1 from '@/assets/images/verification/v1.jpg'
import v2 from '@/assets/images/verification/v2.jpg'
import v3 from '@/assets/images/verification/v3.jpg'
import v4 from '@/assets/images/verification/v4.jpg'
import v5 from '@/assets/images/verification/v5.jpg'
import v6 from '@/assets/images/verification/v6.jpg'
import router from '@/router'
import { validateCommonEmail, validateCommonPassword } from '@/util/validation'

export default {
  data() {
    const validateEmail = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('loginClient.formValidation.emailReq')));
      } else {
        if (!validateCommonEmail(value)) {
          callback(new Error(this.$t('common.formValidation.emailFormatWrong')));
        } else {
          callback()
        }
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.pwReq')));
      } else {
        if (!validateCommonPassword(value)) {
          callback(new Error(this.$t('common.formValidation.passwordFormatWrong')));
        } else {
          callback()
        }
      }
    }
    return {
      needVerification: false,
      msg: '',
      imgs: [v1, v2, v3, v4, v5, v6],
      loginForm: {
        userName_login: '',
        password_login: '',
        gcaptchatoken: '',
      },
      loginFormRules: {
        userName_login: [
          { required: true, validator: validateEmail, trigger: 'blur' }
        ],
        password_login: [
          { required: true, validator: validatePassword, trigger: 'blur' }
        ],
      },
    }
  },
  computed: {
    failedSecondTime() {
      return this.$store.state.common.failedSecondTime
    },
  },
  methods: {
    invokeVerification() {
      this.needVerification = true
    },
    onSuccess() {
      apiSecurityPassed({
        data: rsa('feliscatus:' + Number(new Date())),
      })
        .then(resp => {
          this.needVerification = false
          this.$store.commit('common/setFailedSecondTime', false)
        })
        .catch(err => {
          console.log(err)
        })
      this.msg = ''
    },
    onFail() {
      this.msg = this.$t('loginClient.failedMasseage')
    },
    onRefresh() {
      this.msg = ''
    },
    loginSubmit() {
      this.$refs['loginForm'].validate(valid => {
        if (valid) this.checkIfAllowed()
      })
    },
    //检测到非人为操作滑动时触发的回调函数
    onAgain() {
      this.msg = this.$t('loginClient.failedMasseage')
      this.$refs.slideblock.reset()
    },
    checkIfAllowed() {
      if (!this.failedSecondTime) {
        this.login()
      } else {
        this.invokeVerification()
      }
    },
    login() {
      const userName_login = rsa(this.loginForm.userName_login)
      const password_login = md5(this.loginForm.password_login)
      helper.signIn(userName_login, password_login)
    },
    onForgotPass() {
      router.push('/forgetPassword')
    }
  },
  mounted() {
    setTimeout(function () {
      $('.default-from-box')
        .css('animation', 'none')
        .css('display', 'none')
      $('.from-box').css('display', 'block')
    }, 1000)
  },
}
